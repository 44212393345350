import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import Heading from '@jpp/atoms/Heading';
import Button from '@jpp/molecules/Button';
import InputField from '@jpp/molecules/Form/InputField';
import { ImageScroller } from '@jpp/organisms/AcfComponents';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Section from '@jpp/shared/Grid/Section';
import WYSIWYG from '@jpp/shared/WYSIWYG';
import {
  ECtaTheme,
  EParallaxSize,
  EPosition,
  ESize,
} from 'common/typings/enums';
import axios from 'common/utils/axios/internal';

import './NewsletterBlock.scss';

export interface INewsletterBlockProps {
  heading: string;
  content?: string;
  campaignId?: string;
  image?: Core.IMedia;
}

type TNewsletterBlock = TAcfComponent<INewsletterBlockProps>;

const ROOT_CLASSNAME = 'NewsletterBlock';

export const NewsletterBlock: React.FunctionComponent<TNewsletterBlock> = ({
  theme,
  className,
  heading,
  content,
  image,
  campaignId,
}) => {
  const [isSent, setIsSent] = useState(false);
  const Wrapper = image ? ImageScroller : React.Fragment;
  const wrapperProps = image
    ? {
        image,
        size: EParallaxSize.Large,
        className: `${ROOT_CLASSNAME}__image`,
      }
    : {};

  return (
    <Section
      tagElement="article"
      className={classNames(ROOT_CLASSNAME, className)}
      withPadding={!image}
      theme={theme}
    >
      <Wrapper {...wrapperProps}>
        <Container className={`${ROOT_CLASSNAME}__container`}>
          <div className={`${ROOT_CLASSNAME}__content`}>
            {heading && (
              <Heading className={`${ROOT_CLASSNAME}__heading`} priority={2}>
                {heading}
              </Heading>
            )}
            {content && (
              <WYSIWYG
                className={classNames(`${ROOT_CLASSNAME}__copy`)}
                textAlign={EPosition.Center}
                content={content}
              />
            )}

            {isSent ? (
              <>
                <Heading
                  className={`${ROOT_CLASSNAME}__sub-heading`}
                  priority={3}
                >
                  Thanks for signing up!
                </Heading>
              </>
            ) : (
              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    if (!values.email) {
                      return;
                    }
                    await axios.post('/klaviyo/newsletter', {
                      email: values.email,
                      campaignId,
                    });
                    setIsSent(true);
                    return resetForm();
                  } catch (e) {
                    return;
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form className={`${ROOT_CLASSNAME}__form`}>
                    <InputField
                      name="email"
                      type="email"
                      label="Email Address"
                      className={`${ROOT_CLASSNAME}__input`}
                      placeholder="Email address"
                      required={true}
                    />

                    <Button
                      type="submit"
                      theme={ECtaTheme.Brand}
                      behaviour="action"
                      size={ESize.Sm}
                      isFull={true}
                      disabled={isSubmitting}
                      className={classNames(`${ROOT_CLASSNAME}__button`, {
                        [`${ROOT_CLASSNAME}__button--is-disabled`]:
                          isSubmitting,
                      })}
                    >
                      Sign up
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Container>
      </Wrapper>
    </Section>
  );
};
