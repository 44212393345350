import classNames from 'classnames';
import React, { ReactText } from 'react';

import Heading, { LabelHeading } from '@jpp/atoms/Heading';
import { Button } from '@jpp/molecules/Button/Button';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { ECtaTheme, EPageType } from 'common/typings/enums';
import { NOT_FOUND_STATUS_CODE } from 'common/utils/constants';

import './Error.scss';

interface IErrorProps {
  className?: string;
  statusCode: ReactText;
}

const ROOT_CLASSNAME = 'Error';

export const Error: React.FunctionComponent<IErrorProps> = ({
  className,
  statusCode,
}) => {
  const render404Content = (
    <Heading priority={2} className={`${ROOT_CLASSNAME}__heading`}>
      oops! This page cannot be found.
    </Heading>
  );

  const render500Content = (
    <>
      <Heading priority={2} className={`${ROOT_CLASSNAME}__heading`}>
        We seem to be having technical difficulties...
      </Heading>
      <Heading priority={3} className={`${ROOT_CLASSNAME}__subheading`}>
        Please try again in a bit.
      </Heading>
    </>
  );

  const isNotFound = parseInt(`${statusCode}`, 10) === NOT_FOUND_STATUS_CODE;

  return (
    <Section
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${statusCode}`
      )}
    >
      <Container>
        <Row>
          <Flex colLg={8} className="mx-auto text-center">
            <LabelHeading
              heading={`${statusCode}`}
              className={`${ROOT_CLASSNAME}__label-heading mx-auto`}
            />

            {isNotFound && render404Content}
            {!isNotFound && render500Content}

            <Button
              className={`${ROOT_CLASSNAME}__button`}
              theme={ECtaTheme.BrandOutline}
              behaviour="router"
              link={EPageType.Index}
            >
              Return home
            </Button>
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
