import classNames from 'classnames';
import React from 'react';

import { LabelHeading } from '@jpp/atoms/Heading';
import Section from '@jpp/shared/Grid/Section';

import { TAcfComponent } from '../../types';
import './MapBlock.scss';

interface IMapBlockProps {
  heading?: string;
  map_iframe_url: string;
}

type TMapBlock = TAcfComponent<IMapBlockProps>;

const ROOT_CLASSNAME = 'MapBlock';

export const MapBlock: React.FunctionComponent<TMapBlock> = ({
  map_iframe_url,
  heading,
  className,
}) => {
  return (
    <Section
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--with-heading`]: !!heading,
      })}
      withPadding={false}
    >
      <iframe
        className={`${ROOT_CLASSNAME}__map`}
        src={map_iframe_url}
        width="100%"
        height="100%"
        frameBorder="0"
        title="Map showing Gipsy Hill Brewing Company"
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden={false}
        tabIndex={0}
      />

      {heading && (
        <LabelHeading
          className={`${ROOT_CLASSNAME}__heading`}
          heading={heading}
        />
      )}
    </Section>
  );
};
