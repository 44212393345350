import classNames from 'classnames';
import React from 'react';

import PageBlockCard from '@jpp/molecules/PageBlockCard';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { IPageObjectState } from 'common/redux/page/reducer';
import { EStatus } from 'common/typings/enums';

import './ExperiencePageBlocks.scss';

interface IPageBlock {
  page: IPageObjectState;
  short_description: string;
  cta_content: string;
}

interface IExperiencePageBlocksProps {
  className?: string;
  blocks: IPageBlock[];
}

const ROOT_CLASSNAME = 'ExperiencePageBlocks';

const ExperiencePageBlocks: React.FunctionComponent<
  TAcfComponent<IExperiencePageBlocksProps>
> = ({ className, blocks, theme }) => {
  if (!blocks) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container>
        <Row>
          {blocks.map(
            (
              {
                short_description,
                cta_content,
                page: {
                  post_title,
                  post_status,
                  featured_image,
                  post_type,
                  post_name: slug,
                },
              },
              index
            ) => {
              if (post_status !== EStatus.Publish) {
                return null;
              }

              return (
                <Flex
                  key={index}
                  colMd={6}
                  className={`${ROOT_CLASSNAME}__card-wrapper`}
                >
                  <PageBlockCard
                    className={`${ROOT_CLASSNAME}__page-card`}
                    heading={post_title}
                    slug={slug}
                    pageType={post_type}
                    image={featured_image}
                    description={short_description}
                    ctaContent={cta_content}
                  />
                </Flex>
              );
            }
          )}
        </Row>
      </Container>
    </Section>
  );
};

export default ExperiencePageBlocks;
