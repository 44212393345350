import { IEventStoreState } from 'common/redux/event/reducer';

export interface IEventAPIState extends IEventStoreState {
  author_id: number;
  author_nicename: string;
  permalink: string;
  featured_image?: Core.IMedia;
  ID?: number;
  post_name?: string;
  post_title?: string;
}

export const eventTransform = (event: IEventAPIState): IEventStoreState => {
  const {
    author_id,
    ID,
    post_status,
    post_name,
    post_title,
    author_nicename,
    permalink,
    slug,
    id,
    title,
    ...rest
  } = event;

  return {
    ...rest,
    id: ID || id,
    title: post_title || title,
    slug: post_name || slug,
    post_status,
  };
};

export default eventTransform;
