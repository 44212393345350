import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import ProductCta from '@jpp/atoms/ProductCta';
import { Button } from '@jpp/molecules/Button/Button';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import { IPdpImageGallery, IPdpStoreState } from 'common/redux/pdp/reducer';
import {
  trackGoogleProductClick,
  trackGoogleProductImpression,
} from 'common/tracking/product';
import { EProductRenderedBy } from 'common/tracking/types';
import { ECtaTheme, EPageType, EPosition } from 'common/typings/enums';
import { EProductStock, EProductType } from 'common/typings/enums/product';

import './ProductSpotlight.scss';

interface IProductSpotlight {
  heading: string;
  content: string;
  pdp: IPdpStoreState;
}

type TProductSpotlight = TAcfComponent<IProductSpotlight>;

const ROOT_CLASSNAME = 'ProductSpotlight';

export const ProductSpotlight: React.FunctionComponent<TProductSpotlight> = ({
  theme,
  heading,
  content,
  className,
  pdp,
}) => {
  const acf = pdp.acf_fields || pdp.acf;
  const stockStatus = pdp.stock_status || acf?.default_product?.stock_status;

  React.useEffect(() => {
    trackGoogleProductImpression([pdp], EProductRenderedBy.ProductSpotlight);
  }, []);

  if (!acf || !pdp || stockStatus !== EProductStock.InStock) {
    return null;
  }

  const { default_product, image_gallery, product_type, default_quantity } =
    acf;
  const isBundleProduct = product_type === EProductType.Bundle;
  const isGiftCard = pdp.pdp_type === EProductType.GiftCard;
  const { featured_image: pdpFeaturedImage } = pdp;
  const { image: defaultImage, featured_image } = default_product || {};
  const allImages: IPdpImageGallery[] = [
    { image: featured_image },
    { image: pdpFeaturedImage },
    { image: defaultImage },
    ...image_gallery,
  ];
  const images = allImages.filter((allImage) => allImage.image !== undefined);
  const productId = default_product.ID || default_product.id;
  const MAX_IMAGES = 2;
  const slug = isGiftCard
    ? `/${EPageType.Product}/${pdp.post_name}`
    : `/${EPageType.Product}/${pdp.slug}`;

  const handleTrackingClick = () => {
    trackGoogleProductClick(pdp, 0, EProductRenderedBy.ProductSpotlight);
  };

  if (
    pdp.pdp_type === EProductType.Subscription ||
    pdp.pdp_type === EProductType.SubscribeAndSave
  ) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container>
        <Row>
          <Flex colLg={10} className="mx-auto text-center">
            <Heading
              className={`${ROOT_CLASSNAME}__heading`}
              priority={2}
              headingStyle={1}
              isBold={true}
            >
              {heading}
            </Heading>
          </Flex>
        </Row>

        {images && (
          <Row className={`${ROOT_CLASSNAME}__image-gallery`}>
            {images.map(({ image }, index) => {
              if (!image || index + 1 > MAX_IMAGES) {
                return null;
              }

              return (
                <Flex
                  colLg={4}
                  colMd={6}
                  key={index}
                  className={`${ROOT_CLASSNAME}__image-wrapper`}
                >
                  <RefImage
                    className={`${ROOT_CLASSNAME}__image`}
                    image={image as Core.IMedia}
                  />
                </Flex>
              );
            })}
          </Row>
        )}

        <Row>
          <Flex colLg={10} className="mx-auto">
            <WYSIWYG content={content} textAlign={EPosition.Left} />
          </Flex>
        </Row>

        <Row className={`${ROOT_CLASSNAME}__ctas-wrapper`}>
          <Flex colLg={3} colMd={4} col={6}>
            <ProductCta
              product={pdp}
              productId={productId}
              quantity={
                isBundleProduct || isGiftCard
                  ? 1
                  : parseFloat(default_quantity?.value)
              }
            />
          </Flex>

          <Flex colLg={3} colMd={4} col={6}>
            <Button
              behaviour="router"
              link={slug}
              isFull={true}
              theme={ECtaTheme.BrandOutline}
              onClick={handleTrackingClick}
            >
              More info
            </Button>
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
