import classNames from 'classnames';
import React from 'react';

import { Button } from '@jpp/molecules/Button/Button';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { ECtaTheme, EPageType, ETheme } from 'common/typings/enums';

import './ProductCategoryMenu.scss';

const ROOT_CLASSNAME = 'ProductCategoryMenu';

export interface IProductCategoryMenuProps {
  categories: Core.IProductCategoryMenu[];
}

type TProductCategoryMenu = TAcfComponent<IProductCategoryMenuProps>;

export const ProductCategoryMenu: React.FunctionComponent<
  TProductCategoryMenu
> = ({ categories, className }) => {
  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <Section
      className={classNames(ROOT_CLASSNAME, className)}
      theme={ETheme.TintAlpha}
    >
      <Container>
        <Row className={`${ROOT_CLASSNAME}__row`}>
          {categories.map((categoryGroup, index) => {
            const { category } = categoryGroup || {};
            const { count, slug, name, id } = category || {};

            if (count === 0) {
              return null;
            }

            return (
              <Flex
                key={`${id} ${index}`}
                className={`${ROOT_CLASSNAME}__button-wrapper`}
                colLg={2}
                colMd={3}
                colSm={4}
              >
                <Button
                  className={`${ROOT_CLASSNAME}__button`}
                  theme={ECtaTheme.BrandOutline}
                  link={`/${EPageType.Products}/${slug}`}
                  isFull={true}
                >
                  {name}
                </Button>
              </Flex>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};
