import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import Button from '@jpp/molecules/Button';
import { IEventStoreState } from 'common/redux/event/reducer';
import { ECtaTheme, EPageType } from 'common/typings/enums';
import { getPriceWithCurrency } from 'common/utils/shared/product';

import './EventCard.scss';

interface IEventCardProps {
  className?: string;
  event: IEventStoreState;
}

const ROOT_CLASSNAME = 'EventCard';

const EventCard: React.FunctionComponent<IEventCardProps> = ({
  className,
  event,
}) => {
  const { title, acf_fields, media, slug, featured_image, ...rest } = event;
  const imageSrc = media?.large;
  const image =
    featured_image ||
    ({
      url: imageSrc,
      alt: `${title} image`,
    } as Core.IMedia);
  const acf = acf_fields || rest.acf;
  const startDate = acf?.start_date;
  const price = acf?.price
    ? getPriceWithCurrency(parseFloat(acf?.price))
    : '£FREE';

  return (
    <section className={classNames(ROOT_CLASSNAME, className)}>
      {image && (
        <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
          <RefImage className={`${ROOT_CLASSNAME}__image`} image={image} />
        </div>
      )}
      <AnchorLink
        href={`/${EPageType.Event}/${slug}`}
        className={`${ROOT_CLASSNAME}__link`}
      >
        <Heading
          className={`${ROOT_CLASSNAME}__heading`}
          priority={3}
          children={title}
        />
      </AnchorLink>
      <p className={`${ROOT_CLASSNAME}__date`}>{startDate}</p>
      <p className={`${ROOT_CLASSNAME}__price`}>{price}</p>
      <Button
        className={`${ROOT_CLASSNAME}__button`}
        link={`/${EPageType.Event}/${slug}`}
        behaviour="router"
        theme={ECtaTheme.BrandOutline}
        children="More info"
      />
    </section>
  );
};

export default EventCard;
