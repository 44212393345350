import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';

import { TAcfComponent } from '../../types';
import './QuoteBlock.scss';

export interface IQuoteBlockProps {
  quote: string;
  quotee: string;
  tagElement?: Core.TTag;
}

type TQuoteBlock = TAcfComponent<IQuoteBlockProps>;

const ROOT_CLASSNAME = 'QuoteBlock';

export const QuoteBlock: React.FunctionComponent<TQuoteBlock> = ({
  theme,
  tagElement = 'article',
  quote,
  quotee,
  className,
}) => {
  if (!quote) {
    return null;
  }

  return (
    <Section
      tagElement={tagElement}
      className={classNames(ROOT_CLASSNAME, className)}
      theme={theme}
    >
      <Container>
        <Row>
          <Flex colLg={12} className="mx-auto">
            <Heading priority={2} className={`${ROOT_CLASSNAME}__quote`}>
              {`"${quote}"`}
            </Heading>

            {quotee && <p className={`${ROOT_CLASSNAME}__quotee`}>{quotee}</p>}
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
