import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import Heading from '@jpp/atoms/Heading';
import EventCard from '@jpp/molecules/EventCard';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { IReduxState } from 'common/redux/createStore';
import { IEventsStoreState } from 'common/redux/events/reducer';
import { getEvents } from 'common/redux/events/selectors';

import './UpcomingEvents.scss';

interface IUpcomingEventsProps {
  className?: string;
  show_featured_event?: boolean;
  currentEventSlug?: string;
}

const ROOT_CLASSNAME = 'UpcomingEvents';

const UpcomingEvents: React.FunctionComponent<
  TAcfComponent<IUpcomingEventsProps>
> = ({ className, theme, currentEventSlug, show_featured_event = true }) => {
  const { featuredEvents, allEvents } = useSelector(
    (state: IReduxState): IEventsStoreState => getEvents(state)
  );

  const featured = featuredEvents?.events && featuredEvents?.events[0];
  const hasFeaturedEvent = show_featured_event && !!featured;
  const doesCurrentEventMatchFeatured =
    currentEventSlug && hasFeaturedEvent
      ? featured.slug === currentEventSlug
      : hasFeaturedEvent;
  const shouldShowFeatured =
    hasFeaturedEvent && featured && !doesCurrentEventMatchFeatured;
  const MAX_FILTERED_EVENTS: number = shouldShowFeatured ? 2 : 3;
  const filteredAllEvents =
    allEvents?.events
      ?.filter((event) => {
        if (currentEventSlug) {
          return event.id !== featured?.id && event.slug !== currentEventSlug;
        }

        return event.id !== featured?.id;
      })
      ?.slice(0, MAX_FILTERED_EVENTS) || [];

  if (
    (!hasFeaturedEvent && filteredAllEvents.length === 0) ||
    (!show_featured_event && filteredAllEvents.length === 0)
  ) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container className={`${ROOT_CLASSNAME}__container`}>
        <Row>
          {shouldShowFeatured && (
            <Flex
              className={`${ROOT_CLASSNAME}__featured-event`}
              colLg={4}
              colMd={6}
            >
              <Heading
                priority={2}
                className={`${ROOT_CLASSNAME}__heading`}
                children="Featured Event"
              />

              <EventCard
                className={`${ROOT_CLASSNAME}__event`}
                event={featured}
              />
            </Flex>
          )}

          {filteredAllEvents &&
            filteredAllEvents.map((event, index) => {
              const isFirstItem = index === 0;

              return (
                <Flex
                  key={`${event.id} ${index}`}
                  className={`${ROOT_CLASSNAME}__normal-event`}
                  colLg={4}
                  colMd={6}
                >
                  <Heading
                    priority={2}
                    className={`${ROOT_CLASSNAME}__heading`}
                    children={isFirstItem ? 'Upcoming Events' : '&nbsp;'}
                  />

                  <EventCard
                    className={`${ROOT_CLASSNAME}__event`}
                    event={event}
                  />
                </Flex>
              );
            })}
        </Row>
      </Container>
    </Section>
  );
};

export default UpcomingEvents;
