import classNames from 'classnames';
import React from 'react';

import Icons from '@jpp/atoms/Icon/Icon';
import { EIconType } from '@jpp/atoms/Icon/utils/types';
import { ETheme } from 'common/typings/enums';

import './IconGroup.scss';

interface IIconGroupProps {
  className?: string;
  icon: EIconType;
  label?: string;
  theme?: ETheme;
  withName?: boolean;
  inline?: boolean;
}

const ROOT_CLASSNAME = 'IconGroup';

export const IconGroup: React.FunctionComponent<IIconGroupProps> = ({
  className,
  label,
  icon: iconName,
  theme,
  withName = true,
  inline = false,
}) => {
  const Icon = Icons[iconName];
  const content = withName ? iconName : label;

  return (
    <div
      className={classNames(ROOT_CLASSNAME, className, {
        [`theme--${theme}`]: !!theme,
      })}
    >
      <Icon className={`${ROOT_CLASSNAME}__icon`} inline={inline} />

      {content && <span className={`${ROOT_CLASSNAME}__text`}>{content}</span>}
    </div>
  );
};
