import * as Sentry from '@sentry/node';
import { NextPageContext } from 'next';
import React, { PureComponent } from 'react';

import CoreLayout from '@jpp/layouts/CoreLayout';
import ErrorTemplate from '@jpp/templates/Error';
import { NOT_FOUND_STATUS_CODE } from 'common/utils/constants';

interface IErrorPage<Q extends NextPageContext = NextPageContext> {
  statusCode?: Core.TErrorCode;
  code?: Core.TErrorCode;
  originalUrl?: string;
  params?: any;
  query?: Q;
}

class Error extends PureComponent<IErrorPage> {
  static async getInitialProps({ res, err }: any) {
    const error = err ? err.statusCode : 500;
    const statusCode = res ? res.statusCode : error;
    const { originalUrl, params, query } = res?.req || {};

    Sentry.captureException(err);
    await Sentry.flush(2000);

    return {
      statusCode,
      originalUrl,
      params,
      query,
    };
  }

  render() {
    const { statusCode, code } = this.props;
    const errorCode = statusCode || code || NOT_FOUND_STATUS_CODE;
    const isNotFound = parseInt(`${errorCode}`, 10) === NOT_FOUND_STATUS_CODE;
    const title = isNotFound ? '404 Page Not Found' : 'Internal Server Error';
    const description = isNotFound
      ? 'Error page not found'
      : 'We seem to be having technical difficulties';

    return (
      <CoreLayout title={title} description={description}>
        <ErrorTemplate statusCode={errorCode} />
      </CoreLayout>
    );
  }
}

export default Error;
