import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { Button } from '@jpp/molecules/Button/Button';
import { ProductCard } from '@jpp/molecules/ProductCard/ProductCard';
import { DEFAULT_TEXT_STYLES } from '@jpp/organisms/AcfComponents/constants';
import {
  ICta,
  ITextStyles,
  TAcfComponent,
} from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import { IPdpStoreState } from 'common/redux/pdp/reducer';
import { trackGoogleProductImpression } from 'common/tracking/product';
import { EProductRenderedBy } from 'common/tracking/types';

import './FeaturedProducts.scss';

export interface IFeaturedProductsProps {
  products: IPdpStoreState[];
  text_styles: ITextStyles;
  heading?: string;
  content?: string;
  cta: boolean;
  ctas?: ICta[];
  visible: boolean;
}

export type TFeaturedProducts = TAcfComponent<IFeaturedProductsProps>;

const ROOT_CLASSNAME = 'FeaturedProducts';

export const FeaturedProducts: React.FunctionComponent<TFeaturedProducts> = ({
  theme,
  products,
  text_styles = DEFAULT_TEXT_STYLES,
  heading,
  content,
  cta,
  ctas,
  visible,
  className,
}) => {
  const { heading_font, heading_color, text_align, text_color, text_font } =
    text_styles;

  React.useEffect(() => {
    trackGoogleProductImpression(products, EProductRenderedBy.FeaturedProducts);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container>
        {(heading || content) && (
          <Row>
            {heading && !content && (
              <Flex className={`${ROOT_CLASSNAME}__flex`}>
                <Heading
                  priority={2}
                  className={classNames(
                    `${ROOT_CLASSNAME}__heading`,
                    `font-${heading_font}`,
                    `text-${text_align}`,
                    `text-${heading_color}`
                  )}
                >
                  {heading}
                </Heading>
              </Flex>
            )}

            {content && (
              <Flex
                colLg={10}
                colMd={9}
                className={`mr-lg-auto mx-md-auto ml-lg-0 ${ROOT_CLASSNAME}__flex`}
              >
                <WYSIWYG
                  className={classNames(
                    `${ROOT_CLASSNAME}__content`,
                    `font-${text_font}`,
                    `text-${text_color}`,
                    `text-${text_align}`
                  )}
                  content={content}
                  textAlign={text_align}
                />
              </Flex>
            )}
          </Row>
        )}

        <Row className={`${ROOT_CLASSNAME}__card-row`}>
          {products &&
            products.map((product, index) => {
              return (
                <Flex
                  colLg={4}
                  colMd={6}
                  col={12}
                  key={`${product.sku}${index}`}
                  className={`${ROOT_CLASSNAME}__card`}
                >
                  <ProductCard pdp={product} position={index} />
                </Flex>
              );
            })}
        </Row>

        {cta && ctas && (
          <Row className={`${ROOT_CLASSNAME}__button-wrapper`}>
            {ctas.map(
              ({ cta_theme, cta_link, cta_content, external_link }, index) => {
                return (
                  <Flex
                    colLg={4}
                    colMd={6}
                    col={12}
                    className={`mx-auto ${ROOT_CLASSNAME}__flex`}
                    key={index}
                  >
                    <Button
                      className={`${ROOT_CLASSNAME}__button`}
                      theme={cta_theme}
                      behaviour={external_link ? 'anchor' : 'router'}
                      link={cta_link}
                      isFull={true}
                    >
                      {cta_content}
                    </Button>
                  </Flex>
                );
              }
            )}
          </Row>
        )}
      </Container>
    </Section>
  );
};
