import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import {
  DEFAULT_IMAGE_HEIGHT,
  DEFAULT_IMAGE_WIDTH,
} from '@jpp/atoms/Image/utils';
import WYSIWYG from '@jpp/shared/WYSIWYG';
import { EPosition } from 'common/typings/enums';

import './StaffCard.scss';

export interface IAcfTeamMember {
  job_title: string;
  short_description: string;
  facebook: string;
  twitter: string;
  instagram: string;
  linkedin: string;
  images: { image: Core.IMedia }[];
}

export interface IStaffCardProps {
  className?: string;
  id: number;

  title: string;
  slug: string;
  acf: IAcfTeamMember;
}

const ROOT_CLASSNAME = 'StaffCard';

export const StaffCard: React.FunctionComponent<IStaffCardProps> = ({
  className,
  title,
  acf,
}) => {
  const { short_description, job_title } = acf;
  const images = acf.images;
  const hasImage = images?.length > 0;
  const image: Core.IMedia = hasImage
    ? images[0]?.image
    : ({
        alt: `${title} image`,
        url: '../../../favicon/favicon.jpg',
        width: DEFAULT_IMAGE_WIDTH,
        height: DEFAULT_IMAGE_HEIGHT,
      } as Core.IMedia);

  return (
    <section className={classNames(ROOT_CLASSNAME, className)}>
      {image && (
        <div
          className={classNames(`${ROOT_CLASSNAME}__image-wrapper`, {
            [`${ROOT_CLASSNAME}__image-wrapper--default`]: !hasImage,
          })}
        >
          <RefImage
            className={classNames(`${ROOT_CLASSNAME}__image`, {
              [`${ROOT_CLASSNAME}__image--default`]: !hasImage,
            })}
            image={image}
          />
        </div>
      )}

      <Heading
        priority={3}
        headingStyle={4}
        className={`${ROOT_CLASSNAME}__heading`}
        children={title}
      />

      {job_title && (
        <Heading
          priority={4}
          headingStyle={6}
          className={`${ROOT_CLASSNAME}__job-title`}
          children={job_title}
        />
      )}

      {short_description && (
        <WYSIWYG
          textAlign={EPosition.Center}
          className={`${ROOT_CLASSNAME}__content`}
          content={short_description}
        />
      )}
    </section>
  );
};
