import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import StaffCard from '@jpp/molecules/StaffCard';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { staffTransformer } from 'common/utils/transformers/staff.transformer';

import './StaffListBlock.scss';

export interface IStaffListBlockProps {
  heading: string;
  team_members: any[];
}

type TStaffListBlock = TAcfComponent<IStaffListBlockProps>;

const ROOT_CLASSNAME = 'StaffListBlock';

export const StaffListBlock: React.FunctionComponent<TStaffListBlock> = ({
  theme,
  heading,
  team_members: staff,
}) => {
  const team_members = staffTransformer(staff);

  if (!team_members || team_members?.length === 0) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME)} theme={theme}>
      <Container>
        <Row>
          <Flex className="text-center">
            <Heading className={`${ROOT_CLASSNAME}__heading`} priority={2}>
              {heading}
            </Heading>
          </Flex>
        </Row>
        <Row className={`${ROOT_CLASSNAME}__staff-list`}>
          {team_members.map((team_member) => {
            return (
              <Flex
                key={team_member.id}
                colLg={3}
                colMd={4}
                col={6}
                className={`${ROOT_CLASSNAME}__staff-item`}
              >
                <StaffCard
                  className={`${ROOT_CLASSNAME}__staff`}
                  {...team_member}
                />
              </Flex>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};
