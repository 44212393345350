import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { Button } from '@jpp/molecules/Button/Button';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import {
  EFontFamily,
  EPosition,
  ETextTheme,
  ETheme,
} from 'common/typings/enums';

import { ICta, ITextStyles, TAcfComponent } from '../../types';
import './ContentBlock.scss';

export interface IContentBlock {
  cta: boolean;
  ctas?: ICta[];
  contain_image?: boolean;
  position?: EPosition;
  text_styles?: ITextStyles;
  heading?: string;
  subheading?: string;
  content: string;
  image?: Core.IMedia;
}

export type TContentBlock = TAcfComponent<IContentBlock>;

const ROOT_CLASSNAME = 'ContentBlock';

class ContentBlock extends React.PureComponent<TContentBlock> {
  static defaultProps: Pick<TContentBlock, 'text_styles'> = {
    text_styles: {
      heading_color: ETextTheme.TintOmega,
      heading_font: EFontFamily.Oswald,
      text_align: EPosition.Left,
      text_color: ETextTheme.TintOmega,
      text_font: EFontFamily.Lato,
    },
  };

  get ctas(): JSX.Element | null {
    const { ctas, cta } = this.props;

    if (!cta || !ctas || ctas?.length === 0) {
      return null;
    }

    return (
      <div
        className={`${ROOT_CLASSNAME}__ctas ${ROOT_CLASSNAME}__ctas--item-count-${ctas.length}`}
      >
        {ctas.map(
          ({ cta_link, cta_content, cta_theme, external_link }, index) => (
            <Button
              key={index}
              className={`${ROOT_CLASSNAME}__button`}
              theme={cta_theme as any as ETheme}
              behaviour={external_link ? 'anchor' : 'router'}
              link={cta_link}
            >
              {cta_content}
            </Button>
          )
        )}
      </div>
    );
  }

  get bodyContent(): JSX.Element {
    const { heading, subheading, content, position } = this.props;
    const text_styles = this.props.text_styles
      ? this.props.text_styles
      : {
          heading_color: ETextTheme.TintOmega,
          heading_font: EFontFamily.Oswald,
          text_align: EPosition.Left,
          text_color: ETextTheme.TintOmega,
          text_font: EFontFamily.Lato,
        };

    const isPositionCenter = position === EPosition.Center;
    const isCenter = text_styles.text_align === EPosition.Center;
    const columnSizeLg: Core.TColumn = isPositionCenter ? 9 : 6;
    const columnSizeMd: Core.TColumn | undefined = isPositionCenter
      ? 10
      : undefined;

    return (
      <Flex
        colLg={columnSizeLg}
        colMd={columnSizeMd}
        className={classNames(
          `${ROOT_CLASSNAME}__content-wrapper`,
          `text-${text_styles.text_align}`,
          {
            'mx-auto': isCenter,
          }
        )}
      >
        <div className={`${ROOT_CLASSNAME}__content`}>
          {heading && (
            <Heading
              className={classNames(
                `${ROOT_CLASSNAME}__heading`,
                `font-${text_styles?.heading_font}`,
                `text-${text_styles?.text_align}`,
                `text-${text_styles?.heading_color}`,
                {
                  [`${ROOT_CLASSNAME}__heading--with-subheading`]: !!subheading,
                }
              )}
              priority={2}
            >
              {heading}
            </Heading>
          )}

          {subheading && (
            <Heading
              className={classNames(
                `${ROOT_CLASSNAME}__subheading`,
                `font-${text_styles?.heading_font}`,
                `text-${text_styles?.text_align}`,
                `text-${text_styles?.heading_color}`
              )}
              priority={3}
            >
              {subheading}
            </Heading>
          )}

          <WYSIWYG
            className={classNames(
              `${ROOT_CLASSNAME}__content`,
              `font-${text_styles?.text_font}`,
              `text-${text_styles?.text_color}`,
              `text-${text_styles?.text_align}`
            )}
            textAlign={text_styles!.text_align}
            content={content}
          />

          {this.ctas}
        </div>
      </Flex>
    );
  }

  get content(): JSX.Element {
    const { position, image, contain_image } = this.props;

    if (position === EPosition.Center) {
      return this.bodyContent;
    }

    const imageSrc = image?.sizes?.medium_large || image?.url || image?.src;

    return (
      <>
        {this.bodyContent}

        <Flex colLg={6} className={`${ROOT_CLASSNAME}__image-wrapper`}>
          <div
            className={classNames(`${ROOT_CLASSNAME}__image`, {
              [`${ROOT_CLASSNAME}__image--contain`]: !!contain_image,
            })}
            style={{
              backgroundImage: imageSrc && `url(${imageSrc})`,
            }}
          />
        </Flex>
      </>
    );
  }

  render() {
    const { className, position, text_styles, theme } = this.props;

    return (
      <Section
        tagElement="article"
        className={classNames(
          ROOT_CLASSNAME,
          className,
          `${ROOT_CLASSNAME}--position-${position}`,
          `${ROOT_CLASSNAME}--text-align-${text_styles!.text_align}`
        )}
        theme={theme}
      >
        <Container className={`${ROOT_CLASSNAME}__container`}>
          <Row className={`${ROOT_CLASSNAME}__row`}>{this.content}</Row>
        </Container>
      </Section>
    );
  }
}

export default ContentBlock;
