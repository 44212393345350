import classNames from 'classnames';
import React from 'react';

import { LabelHeading } from '@jpp/atoms/Heading';
import { ImageScroller } from '@jpp/organisms/AcfComponents';
import { EParallaxSize, EPosition } from 'common/typings/enums';

import { TAcfComponent } from '../../types';
import './PageHero.scss';

export interface IPageHeroProps {
  className?: string;
  image: Core.IMedia;
  heading?: string;
  headingElement?: 'span';
  position?: EPosition;
}

type TPageHero = TAcfComponent<IPageHeroProps>;

const ROOT_CLASSNAME = 'PageHero';

export const PageHero: React.FunctionComponent<TPageHero> = ({
  image,
  heading,
  position = EPosition.Center,
  headingElement,
  className,
}) => {
  if (!image || !image?.url) {
    return null;
  }

  return (
    <article
      className={classNames(
        ROOT_CLASSNAME,
        `${ROOT_CLASSNAME}--${position}`,
        className
      )}
    >
      <ImageScroller
        className={`${ROOT_CLASSNAME}__image`}
        size={EParallaxSize.Medium}
        image={image}
      >
        <div className={`${ROOT_CLASSNAME}__container`}>
          {heading && (
            <LabelHeading
              className={`${ROOT_CLASSNAME}__heading`}
              heading={heading}
              headingElement={headingElement}
            />
          )}
        </div>
      </ImageScroller>
    </article>
  );
};
