import classNames from 'classnames';
import React from 'react';

import { UnderlineHeading } from '@jpp/atoms/Heading';
import EventCard from '@jpp/molecules/EventCard';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { IEventPostObject } from 'common/redux/event/reducer';
import { EStatus } from 'common/typings/enums';
import eventTransform, {
  IEventAPIState,
} from 'common/utils/transformers/event.transformer';

import './FeaturedEvents.scss';

interface IFeaturedEventsProps {
  className?: string;
  heading: string;
  events: IEventPostObject[];
}

const ROOT_CLASSNAME = 'FeaturedEvents';

const FeaturedEvents: React.FunctionComponent<
  TAcfComponent<IFeaturedEventsProps>
> = ({ className, theme, heading, ...props }) => {
  const events = props?.events?.filter(
    (event) => event.post_status === EStatus.Publish
  );

  if (!events || events.length === 0) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container>
        <Row>
          <Flex>
            <UnderlineHeading
              className={`${ROOT_CLASSNAME}__heading`}
              title={heading}
            />
          </Flex>
        </Row>
        <Row>
          {events.map((event, index) => {
            return (
              <Flex
                key={`${event.ID} ${index}`}
                className={`${ROOT_CLASSNAME}__event-wrapper`}
                colLg={4}
                colMd={6}
              >
                <EventCard
                  className={`${ROOT_CLASSNAME}__event`}
                  event={eventTransform(event as IEventAPIState)}
                />
              </Flex>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

export default FeaturedEvents;
