import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';

import useAuthUser from '@jpp/hooks/useAuthUser';
import CoreLayout from '@jpp/layouts/CoreLayout';
import { trackCustomer } from 'common/tracking/customer';
import { ELayout, EPageType } from 'common/typings/enums';
import {
  APP_TITLE,
  NOT_FOUND_STATUS_CODE,
  SITE_DESCRIPTION,
} from 'common/utils/constants';

import Error from '../../../../pages/_error';

interface IPageHandlerProps {
  className?: string;
  children?: ReactNode;
  template?: React.ComponentType<any>;
  title?: string;
  description?: string;
  layout?: ELayout;
  page: any;
  pageType?: EPageType;
  error?: Core.IErrorResponse | AxiosError | any;
}

const PageHandler: React.FunctionComponent<IPageHandlerProps> = ({
  page = {},
  error,
  pageType = EPageType.Page,
  children,
  template: Template,
  layout = ELayout.Core,
  ...rest
}) => {
  const router = useRouter();
  const customer = useAuthUser();

  useEffect(() => {
    if (customer?.isLoggedIn) {
      trackCustomer(customer);
    }
  }, [customer?.isLoggedIn]);

  if (router.isFallback) {
    return <>Loading...</>;
  }

  const is404 = !!error || !!page?.error || Object.keys(page).length === 0;

  if (is404) {
    return <Error {...error} statusCode={NOT_FOUND_STATUS_CODE} />;
  }

  const childProps = {
    page,
    pageType,
    children,
    ...rest,
  };

  const layoutMap = new Map<ELayout, React.ComponentType<any>>();
  layoutMap.set(ELayout.Core, CoreLayout);

  const Layout = layoutMap[layout] || CoreLayout;
  const content = (Template ? <Template {...childProps} /> : children) || (
    <React.Fragment />
  );
  const title =
    rest.title || page?.yoast?.yoast_wpseo_title || page?.title || APP_TITLE;
  const description =
    page?.yoast?.yoast_wpseo_metadesc || page?.description || SITE_DESCRIPTION;

  return <Layout title={title} description={description} children={content} />;
};

export default PageHandler;
