import classNames from 'classnames';
import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax/cjs';

import Heading from '@jpp/atoms/Heading';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import { EParallaxSize, EPosition, ETextTheme } from 'common/typings/enums';

import { DEFAULT_TEXT_STYLES } from '../../constants';
import { ITextStyles, TAcfComponent } from '../../types';
import './ImageScroller.scss';

interface IImageScrollerProps {
  size?: EParallaxSize;
  imageSrc?: string;
  image?: Core.IMedia;
  heading?: string;
  content?: string;
  text_styles?: ITextStyles;
}

type TImageScroller = TAcfComponent<IImageScrollerProps>;

const ROOT_CLASSNAME = 'ImageScroller';

const altContent = (
  text_styles: ITextStyles,
  heading?: string,
  content?: string
): JSX.Element | null => {
  if (!heading || !content) {
    return null;
  }
  return (
    <>
      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        {heading && (
          <Heading
            priority={2}
            className={classNames(
              `${ROOT_CLASSNAME}__heading`,
              `font-${text_styles?.heading_font}`,
              `text-${text_styles?.text_align}`,
              `text-${text_styles?.heading_color}`
            )}
          >
            {heading}
          </Heading>
        )}

        {content && (
          <WYSIWYG
            className={classNames(
              `${ROOT_CLASSNAME}__content`,
              `font-${text_styles?.text_font}`,
              `text-${text_styles?.text_color}`,
              `text-${text_styles?.text_align}`
            )}
            textAlign={text_styles?.text_align}
            content={content}
          />
        )}
      </div>
    </>
  );
};

export const ImageScroller: React.FunctionComponent<TImageScroller> = ({
  className,
  size = EParallaxSize.Medium,
  image,
  heading,
  content,
  text_styles = {
    ...DEFAULT_TEXT_STYLES,
    heading_color: ETextTheme.TintAlpha,
    text_align: EPosition.Center,
    text_color: ETextTheme.TintAlpha,
  },
  children,
}) => {
  if (!image) {
    return null;
  }

  const innerContent = children
    ? children
    : altContent(text_styles, heading, content);

  const src =
    image?.sizes?.large || image?.sizes?.medium_large || image.url || image.src;

  return (
    <ParallaxBanner
      className={classNames(
        className,
        ROOT_CLASSNAME,
        `${ROOT_CLASSNAME}--${size}`
      )}
      layers={[
        {
          amount: 0.3,
          children: undefined,
          image: src,
        },
      ]}
    >
      {innerContent}
    </ParallaxBanner>
  );
};
