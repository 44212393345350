import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { EIconType } from '@jpp/atoms/Icon/utils/types';
import RefImage from '@jpp/atoms/Image';
import { IconGroup } from '@jpp/molecules/IconGroup/IconGroup';
import SellBlock from '@jpp/molecules/SellBlock';
import { ESellBlockRenderedBy } from '@jpp/molecules/SellBlock/types';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import { IPdpAcfFields, IPdpStoreState } from 'common/redux/pdp/reducer';
import { trackGoogleProductImpression } from 'common/tracking/product';
import { EProductRenderedBy } from 'common/tracking/types';
import { EPosition } from 'common/typings/enums';
import { EProductStock, EProductType } from 'common/typings/enums/product';

import './FeaturedProduct.scss';

export interface IFeaturedProductProps {
  heading: string;
  content: string;
  image?: Core.IMedia;
  position?: EPosition;
  pdp: IPdpStoreState;
}

type TFeaturedProduct = TAcfComponent<IFeaturedProductProps>;

const ROOT_CLASSNAME = 'FeaturedProduct';

export const FeaturedProduct: React.FunctionComponent<TFeaturedProduct> = ({
  theme,
  className,
  pdp,
  image: featuredImage,
  heading,
  content,
  position = EPosition.Right,
}) => {
  const acf = pdp?.acf_fields || (pdp?.acf as IPdpAcfFields);
  const stockStatus = pdp.stock_status || acf?.default_product?.stock_status;
  const isGiftCard = pdp.pdp_type === EProductType.GiftCard;

  React.useEffect(() => {
    trackGoogleProductImpression([pdp], EProductRenderedBy.FeaturedProduct);
  }, []);

  if (!acf || stockStatus !== EProductStock.InStock) {
    return null;
  }

  const image =
    featuredImage ||
    pdp?.image ||
    (acf.image_gallery && acf.image_gallery[0]?.image);

  if (
    pdp.pdp_type === EProductType.Subscription ||
    pdp.pdp_type === EProductType.SubscribeAndSave
  ) {
    return null;
  }

  return (
    <Section
      tagElement="article"
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--position-${position}`
      )}
      theme={theme}
    >
      <Container className={`${ROOT_CLASSNAME}__container`}>
        <Row className={`${ROOT_CLASSNAME}__row`}>
          <Flex colLg={6} className={`${ROOT_CLASSNAME}__image-wrapper`}>
            {image && (
              <RefImage
                image={image as Core.IMedia}
                className={classNames(`${ROOT_CLASSNAME}__image`)}
                shouldLazyLoad={false}
              />
            )}
          </Flex>

          <Flex
            colLg={6}
            className={classNames(`${ROOT_CLASSNAME}__content-wrapper`)}
          >
            <Heading
              className={classNames(`${ROOT_CLASSNAME}__heading`)}
              priority={2}
              headingStyle={1}
              isBold={true}
            >
              {heading}
            </Heading>

            {acf.beer_features && (
              <div className={`${ROOT_CLASSNAME}__beer-features`}>
                {acf.beer_features.map((feature) => {
                  if (!feature) {
                    return null;
                  }

                  return (
                    <IconGroup
                      key={feature}
                      icon={feature as EIconType}
                      withName={true}
                      className={`${ROOT_CLASSNAME}__icon`}
                    />
                  );
                })}
              </div>
            )}

            {!isGiftCard && (
              <Heading
                className={classNames(
                  `${ROOT_CLASSNAME}__subheading`,
                  `${ROOT_CLASSNAME}__subheading--about`
                )}
                headingStyle="5"
                priority={3}
              >
                About this beer
              </Heading>
            )}

            <WYSIWYG
              className={classNames(`${ROOT_CLASSNAME}__content`)}
              content={content}
            />

            <SellBlock
              className={`${ROOT_CLASSNAME}__sell-block ${ROOT_CLASSNAME}__sell-block--${pdp.pdp_type}`}
              pdp={pdp}
              renderedBy={ESellBlockRenderedBy.ProductWidget}
            />
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
