import { EFontFamily, EPosition, ETextTheme } from 'common/typings/enums';

import { NewsletterBlock } from './components';
import ContentBlock from './components/ContentBlock';
import CtaBanner from './components/CtaBanner';
import ExperiencePageBlocks from './components/ExperiencePageBlocks';
import FeaturedBlogPost from './components/FeaturedBlogPost';
import FeaturedEvent from './components/FeaturedEvent';
import FeaturedEvents from './components/FeaturedEvents';
import FeaturedProduct from './components/FeaturedProduct';
import FeaturedProducts from './components/FeaturedProducts';
import ImageScroller from './components/ImageScroller';
import MapBlock from './components/MapBlock';
import PageHero from './components/PageHero';
import ProductCategoryMenu from './components/ProductCategoryMenu';
import ProductSpotlight from './components/ProductSpotlight';
import QuoteBlock from './components/QuoteBlock';
import StaffListBlock from './components/StaffListBlock';
import StaticText from './components/StaticText';
import TimelineContentBlock from './components/TimelineContentBlock';
import UpcomingEvents from './components/UpcomingEvents';
import { EAcfComponentType, ITextStyles, TAcfComponentMap } from './types';

export const ACF_COMPONENT_MAP: TAcfComponentMap = {
  [EAcfComponentType.PageHero]: PageHero,
  [EAcfComponentType.ContentBlock]: ContentBlock,
  [EAcfComponentType.CtaBanner]: CtaBanner,
  [EAcfComponentType.ExperiencePageBlocks]: ExperiencePageBlocks,
  [EAcfComponentType.FeaturedProducts]: FeaturedProducts,
  [EAcfComponentType.FeaturedProduct]: FeaturedProduct,
  [EAcfComponentType.ProductSpotlight]: ProductSpotlight,
  [EAcfComponentType.ProductCategoryMenu]: ProductCategoryMenu,
  [EAcfComponentType.QuoteBlock]: QuoteBlock,
  [EAcfComponentType.FeaturedEvent]: FeaturedEvent,
  [EAcfComponentType.FeaturedEvents]: FeaturedEvents,
  [EAcfComponentType.ImageScroller]: ImageScroller,
  [EAcfComponentType.FeaturedBlogPost]: FeaturedBlogPost,
  [EAcfComponentType.TimelineContentBlock]: TimelineContentBlock,
  [EAcfComponentType.MapBlock]: MapBlock,
  [EAcfComponentType.StaffListBlock]: StaffListBlock,
  [EAcfComponentType.StaticText]: StaticText,
  [EAcfComponentType.UpcomingEvents]: UpcomingEvents,
  [EAcfComponentType.NewsletterBlock]: NewsletterBlock,
};

export const DEFAULT_TEXT_STYLES: ITextStyles = {
  text_align: EPosition.Left,
  heading_color: ETextTheme.TintOmega,
  heading_font: EFontFamily.Oswald,
  text_color: ETextTheme.TintOmega,
  text_font: EFontFamily.Lato,
};
