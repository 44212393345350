import React from 'react';

import { ACF_COMPONENT_MAP } from '@jpp/organisms/AcfComponents/constants';
import InViewportWatcher from '@jpp/shared/InViewPortWatcher';
import { EPageType, ETheme } from 'common/typings/enums';

import { EAcfComponentType, IAcfComponentsProps, TAcfComponent } from './types';

export const AcfComponents: React.FunctionComponent<IAcfComponentsProps> = ({
  components = [],
  page_theme = ETheme.TintAlpha,
  pageType,
  className,
}) => {
  return (
    <>
      {components?.map((component, index) => {
        if (!component || !component?.acf_fc_layout) {
          return null;
        }

        const componentName = component?.acf_fc_layout;
        const shouldNotRenderExpPageBlocks =
          componentName === EAcfComponentType.ExperiencePageBlocks &&
          pageType !== EPageType.Experience;
        const componentProps = {
          className,
          theme: page_theme,
          ...component,
        };
        const Component: React.ComponentType<TAcfComponent> =
          ACF_COMPONENT_MAP[componentName];

        if (!Component || shouldNotRenderExpPageBlocks) {
          console.warn('componentName', componentName);
          return null;
        }

        return (
          <InViewportWatcher
            key={`${componentName} ${index}`}
            rootMargin="50px"
            shouldOnlyTriggerOnce={true}
            threshold={0}
          >
            <Component {...componentProps} />
          </InViewportWatcher>
        );
      })}
    </>
  );
};

export default React.memo(AcfComponents);
