import React from 'react';

import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';

import './CtaBanner.scss';

type TCtaBanner = TAcfComponent;

// const ROOT_CLASSNAME = 'CtaBanner';

export const CtaBanner: React.FunctionComponent<TCtaBanner> = ({
  theme,
  className,
}) => {
  return null;
};
