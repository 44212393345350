import React from 'react';

import {
  ICta,
  ITextStyles,
  TAcfComponent,
} from '@jpp/organisms/AcfComponents/types';
import { ECtaTheme, EPosition, EStatus } from 'common/typings/enums';

import ContentBlock from '../ContentBlock';
import './FeaturedBlogPost.scss';

const ROOT_CLASSNAME = 'FeaturedBlogPost';

interface IBlogPost {
  id: number;
  ID: number;
  post_name: string;
  post_title: string;
  post_status: EStatus;
  post_content: string;
  post_excerpt?: string;
}

interface IFeaturedBlogPost {
  cta: boolean;
  ctas?: ICta[];
  position?: EPosition;
  text_styles?: ITextStyles;
  content: string;
  blog_post: IBlogPost;
}

type TFeaturedBlogPost = TAcfComponent<IFeaturedBlogPost>;

const FeaturedBlogPost: React.FunctionComponent<TFeaturedBlogPost> = ({
  theme,
  ...props
}) => {
  const { cta, position, text_styles, blog_post } = props;

  if (!blog_post) {
    return null;
  }

  const { post_excerpt, post_title, post_name } = blog_post;
  const content = post_excerpt || '';

  const ctas = !props.ctas
    ? [
        {
          cta_content: 'Read more',
          cta_link: post_name,
          cta_theme: ECtaTheme.Brand,
          external_link: false,
        },
      ]
    : props.ctas;

  return (
    <ContentBlock
      className={ROOT_CLASSNAME}
      content={content}
      heading={post_title}
      text_styles={text_styles}
      position={position}
      theme={theme}
      cta={cta}
      ctas={ctas}
    />
  );
};

export default FeaturedBlogPost;
