import classNames from 'classnames';
import React, { ReactText } from 'react';

import Heading from '@jpp/atoms/Heading';
import { EIconType } from '@jpp/atoms/Icon/utils/types';
import RefImage from '@jpp/atoms/Image/Image';
import { IconGroup } from '@jpp/molecules/IconGroup/IconGroup';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';
import { ETheme } from 'common/typings/enums';

import './TimelineContentBlock.scss';

interface ITimelineContentBlockProps {
  heading: string;
  content: string;
  image: Core.IMedia;
  employee_count: ReactText;
  tank_count: ReactText;
  beer_brewed: ReactText;
}

type TITimelineContentBlock = TAcfComponent<ITimelineContentBlockProps>;

const ROOT_CLASSNAME = 'TimelineContentBlock';

export const TimelineContentBlock: React.FunctionComponent<
  TITimelineContentBlock
> = ({
  theme = ETheme.TintAlpha,
  image,
  heading,
  content,
  employee_count,
  tank_count,
  beer_brewed,
  className,
}) => {
  if (!image) {
    return null;
  }

  return (
    <Section className={classNames(ROOT_CLASSNAME, className)} theme={theme}>
      <Container>
        <Row>
          <Flex colLg={12}>
            <Heading priority={2} className={`${ROOT_CLASSNAME}__heading`}>
              {heading}
            </Heading>
          </Flex>
          <Flex colLg={6} className={`${ROOT_CLASSNAME}__image-wrapper`}>
            <RefImage className={`${ROOT_CLASSNAME}__image`} image={image} />
          </Flex>

          <Flex colLg={4} className={`${ROOT_CLASSNAME}__content-wrapper`}>
            <WYSIWYG
              className={`${ROOT_CLASSNAME}__content`}
              content={content}
            />
          </Flex>

          <Flex colLg={2} className={`${ROOT_CLASSNAME}__icon-wrapper`}>
            {employee_count && (
              <IconGroup
                icon={EIconType.Group}
                className={`${ROOT_CLASSNAME}__icon-group`}
                label={`Employees: ${employee_count}`}
                withName={false}
              />
            )}

            {tank_count && (
              <IconGroup
                icon={EIconType.Brewery}
                className={`${ROOT_CLASSNAME}__icon-group`}
                label={`Tank Count: ${tank_count}`}
                withName={false}
              />
            )}

            {beer_brewed && (
              <IconGroup
                icon={EIconType.Beer}
                className={`${ROOT_CLASSNAME}__icon-group`}
                label={`Beer Brewed: ${beer_brewed}HL`}
                withName={false}
              />
            )}
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};
