import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import Button from '@jpp/molecules/Button';
import { ECtaTheme, EPageType } from 'common/typings/enums';

import './PageBlockCard.scss';

interface IPageBlockCardProps {
  className?: string;
  heading: string;
  slug: string;
  image?: Core.IMedia;
  pageType: string | EPageType;
  description: string;
  ctaContent: string;
}

const ROOT_CLASSNAME = 'PageBlockCard';

const WC_PAGE_TYPE_MAP: Partial<Record<EPageType, EPageType>> = {
  [EPageType.Events]: EPageType.Event,
  [EPageType.Bars]: EPageType.Bar,
};

const PageBlockCard: React.FunctionComponent<IPageBlockCardProps> = ({
  className,
  image,
  heading,
  slug,
  description,
  ctaContent,
  ...props
}) => {
  const imageSrc = image?.sizes?.large;
  const pageType =
    WC_PAGE_TYPE_MAP[
      props.pageType !== EPageType.Page
        ? (props.pageType as EPageType)
        : EPageType.Page
    ];
  const link = pageType ? `/${pageType}/${slug}` : slug;

  return (
    <section className={classNames(ROOT_CLASSNAME, className)}>
      {image && imageSrc && (
        <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
          <RefImage
            className={`${ROOT_CLASSNAME}__image`}
            image={{
              ...image,
              url: imageSrc,
            }}
          />
        </div>
      )}
      <Heading
        className={`${ROOT_CLASSNAME}__heading`}
        priority={3}
        children={heading}
      />
      <p
        className={`${ROOT_CLASSNAME}__copy`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Button
        className={`${ROOT_CLASSNAME}__button`}
        link={link}
        behaviour="router"
        theme={ECtaTheme.BrandOutline}
        children={ctaContent}
      />
    </section>
  );
};

export default PageBlockCard;
