import classNames from 'classnames';
import React from 'react';

import { LabelHeading } from '@jpp/atoms/Heading';
import { TAcfComponent } from '@jpp/organisms/AcfComponents/types';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section';
import { WYSIWYG } from '@jpp/shared/WYSIWYG/WYSIWYG';

import './StaticText.scss';

interface IStaticTextProps {
  className?: string;
  heading: string;
  content: string;
}

type TStaticText = TAcfComponent<IStaticTextProps>;

const ROOT_CLASSNAME = 'StaticText';

const StaticText: React.FunctionComponent<TStaticText> = ({
  className,
  heading,
  content,
}) => {
  return (
    <Section className={classNames(ROOT_CLASSNAME, className)}>
      <Container>
        <Row>
          <Flex
            colLg={9}
            colMd={10}
            className={`${ROOT_CLASSNAME}__content-wrapper mx-auto`}
          >
            <LabelHeading
              className={`${ROOT_CLASSNAME}__heading`}
              heading={heading}
            />

            <WYSIWYG
              className={`${ROOT_CLASSNAME}__content`}
              content={content}
            />
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};

export default StaticText;
