import React from 'react';

import {
  ECtaTheme,
  EFontFamily,
  EPageType,
  EPosition,
  ETextTheme,
} from 'common/typings/enums';

export type TAcfComponent<T = object> = Partial<Core.IAcfComponent> & T;

export interface IAcfComponentsProps {
  className?: string;
  components: TAcfComponent[];
  page_theme: Core.TTheme;
  pageType?: EPageType;
}

export interface ICta {
  cta_link: string;
  cta_theme: ECtaTheme;
  cta_content: string;
  external_link: boolean;
}

export interface ITextStyles {
  text_align: EPosition;
  heading_font: EFontFamily;
  heading_color: ETextTheme;
  text_font: EFontFamily;
  text_color: ETextTheme;
}

export type TAcfComponentMap = Record<
  EAcfComponentType,
  React.ComponentType<TAcfComponent<any>>
>;

export enum EAcfComponentType {
  PageHero = 'page_hero',
  ContentBlock = 'content_block',
  ExperiencePageBlocks = 'experience_page_blocks',
  CtaBanner = 'cta_banner',
  ImageScroller = 'image_scroller',
  FeaturedProducts = 'featured_products',
  FeaturedProduct = 'featured_product',
  ProductSpotlight = 'product_spotlight',
  ProductCategoryMenu = 'product_category_menu',
  QuoteBlock = 'quote_block',
  FeaturedEvent = 'featured_event',
  FeaturedEvents = 'featured_events',
  FeaturedBlogPost = 'featured_blog_post',
  TimelineContentBlock = 'timeline_content_block',
  MapBlock = 'map_block',
  StaffListBlock = 'staff_list_block',
  StaticText = 'static_text',
  UpcomingEvents = 'upcoming_events',
  NewsletterBlock = 'newsletter_block',
}
