import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import { EProductCtaText } from '@jpp/atoms/ProductCta/constants';
import ProductPrice from '@jpp/atoms/ProductPrice';
import Button from '@jpp/molecules/Button';
import { ProductModal } from '@jpp/organisms/Modal';
import { IPdpStoreState } from 'common/redux/pdp/reducer';
import { trackGoogleProductClick } from 'common/tracking/product';
import { EProductRenderedBy } from 'common/tracking/types';
import { ECtaTheme, EPageType, ESize } from 'common/typings/enums';
import { EProductStock, EProductType } from 'common/typings/enums/product';
import { getPriceWithCurrency } from 'common/utils/shared/product';

import './ProductCard.scss';

export interface IProductCardProps {
  className?: string;
  pdp: IPdpStoreState;
  position?: number;
  renderedBy?: EProductRenderedBy;
  bodyOverflow?: boolean;
}

const ROOT_CLASSNAME = 'ProductCard';

export const ProductCard: React.FunctionComponent<IProductCardProps> = ({
  className,
  pdp,
  position,
  renderedBy = EProductRenderedBy.FeaturedProducts,
  bodyOverflow,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  if (!pdp) {
    return null;
  }

  const acf = pdp.acf_fields || pdp.acf;
  const isInStock = pdp.stock_status === EProductStock.InStock;
  const isOnBackorder = pdp.stock_status === EProductStock.OnBackorder;
  const title = pdp.post_title || pdp.title;

  if (!acf) {
    return null;
  }

  const {
    image_gallery,
    subtitle,
    beer_info,
    default_product,
    gift_card_items,
  } = acf;
  const { abv, style } = beer_info || {};
  const slug = pdp.post_name || default_product.slug || pdp.slug;
  const image =
    pdp.featured_image ||
    pdp.image ||
    (image_gallery && image_gallery.length > 0 && image_gallery[0].image);
  const isGiftCard = pdp.pdp_type === EProductType.GiftCard;

  const handleOnClick = () => setIsModalVisible(true);

  const handleOnClose = () => setIsModalVisible(false);

  const handleTrackingClick = () => {
    trackGoogleProductClick(pdp, position, renderedBy);
  };

  return (
    <>
      {isModalVisible && (isInStock || isOnBackorder) && (
        <ProductModal
          className={`${ROOT_CLASSNAME}__modal`}
          onClose={handleOnClose}
          bodyOverflow={bodyOverflow}
          pdp={pdp}
        />
      )}

      <div
        className={classNames(ROOT_CLASSNAME, className, {
          [`${ROOT_CLASSNAME}--is-oos`]: !isInStock && !isOnBackorder,
          [`${ROOT_CLASSNAME}--modal-open`]: isModalVisible,
        })}
      >
        <AnchorLink
          href={`/${EPageType.Product}/${slug}`}
          behaviour="router"
          withStyles={false}
          className={`${ROOT_CLASSNAME}__link`}
          onClick={handleTrackingClick}
        />

        {image && (
          <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
            <RefImage image={image as Core.IMedia} />
          </div>
        )}

        <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
          <Heading
            priority={3}
            className={classNames(`${ROOT_CLASSNAME}__title`, {
              [`${ROOT_CLASSNAME}__title--small`]: title?.length >= 9,
            })}
          >
            {title}
          </Heading>

          {subtitle && (
            <Heading priority={4} className={`${ROOT_CLASSNAME}__subtitle`}>
              {subtitle}
            </Heading>
          )}

          {style && <p className={`${ROOT_CLASSNAME}__style`}>{style}</p>}

          {isGiftCard && gift_card_items ? (
            <p
              className={`${ROOT_CLASSNAME}__abv ${ROOT_CLASSNAME}__abv--price`}
            >
              {`${
                gift_card_items.length === 1 ? '' : 'from'
              } ${getPriceWithCurrency(parseFloat(pdp.price))}`}
            </p>
          ) : (
            <p className={`${ROOT_CLASSNAME}__abv`}>
              <ProductPrice
                regularPrice={pdp.regular_price}
                price={pdp.price}
                quantity={1}
              />
              {abv ? ' | ' + abv + '%' : ''}
            </p>
          )}

          <Button
            behaviour="action"
            theme={ECtaTheme.TintPsiOutline}
            size={ESize.Xs}
            className={`${ROOT_CLASSNAME}__button`}
            onClick={handleOnClick}
          >
            {isInStock || isOnBackorder
              ? EProductCtaText.QuickBuy
              : EProductCtaText.SoldOut}
          </Button>
        </div>
      </div>
    </>
  );
};
