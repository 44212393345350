import { IStaffCardProps } from '@jpp/molecules/StaffCard/StaffCard';
import { EStatus } from 'common/typings/enums';

export const staffTransformer = (
  team_members: any[]
): IStaffCardProps[] | undefined => {
  if (!team_members) {
    return;
  }

  const staff: IStaffCardProps[] = [];
  team_members.forEach(
    ({ ID, post_title, post_name, acf_fields, post_status }) => {
      if (post_status !== EStatus.Publish) {
        return;
      }

      staff.push({
        id: ID,
        title: post_title,
        slug: post_name,
        acf: acf_fields,
      });
    }
  );
  return staff;
};
